exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-geo-js": () => import("./../../../src/pages/geo.js" /* webpackChunkName: "component---src-pages-geo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pubblications-js": () => import("./../../../src/pages/pubblications.js" /* webpackChunkName: "component---src-pages-pubblications-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-towers-js": () => import("./../../../src/pages/towers.js" /* webpackChunkName: "component---src-pages-towers-js" */),
  "component---src-pages-webgis-js": () => import("./../../../src/pages/webgis.js" /* webpackChunkName: "component---src-pages-webgis-js" */),
  "component---src-templates-layout-js": () => import("./../../../src/templates/Layout.js" /* webpackChunkName: "component---src-templates-layout-js" */)
}

